import React, { useRef, useState } from 'react';
import backpic from '../assets/images/contactform.webp';
import Recaptcha from 'react-google-recaptcha';
import '../index.css';
import 'react-phone-number-input/style.css';
import {
    Button,
    Col,
    Div,
    Flex,
    Img,
    Input,
    Label,
    Span,
    Textarea,
} from '../styles/styledElements.js';
import { toast } from 'react-hot-toast';
import PhoneInputWithCountrySelect, {
    isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { Formik } from 'formik';
import * as Yup from 'yup';
const encode = (data) => {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
};

const RECAPTCHA_KEY = '6LeD1K4mAAAAAL3olVRi7CkM7g1c09g4NGttBdaT';

function ContactNew({ closeContact }) {
    const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
    const recaptchaRef = useRef();

    const emailRegex =
        /^[a-z]+(?:[.-]?[a-z0-9]+)@[a-z]+(?:[.-]?[a-z]+)(\.[a-z]{2,3})+$/;

    return (
        <Formik
            initialValues={{
                'form-name': 'enquiry',
                Name: '',
                'Organisation-Name': '',
                'Contact-Number': '',
                Email: '',
                Country: '',
                Message: '',
            }}
            validationSchema={Yup.object({
                Name: Yup.string()
                    .required('Name is required')
                    .matches(
                        /^[a-zA-Z0-9',\-\p{L} ]+$/u,
                        'Provide a proper name'
                    )
                    .test(
                        'no-leading-space',
                        'Name should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    ),
                'Organisation-Name': Yup.string()
                    .required('Company Name is required')
                    .test(
                        'no-leading-space',
                        'Name should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    ),
                'Contact-Number': Yup.string()
                    .required('Contact-Number is required')
                    .test(
                        'Contact-Number',
                        'Enter a valid contact number',
                        (value) => isPossiblePhoneNumber(value)
                    ),
                Email: Yup.string()
                    .matches(emailRegex, 'Invalid email address')
                    .required('Email is required'),
                Country: Yup.string()
                    .required('Country name is required')
                    .test(
                        'no-leading-space',
                        'Country Name should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    ),
                Message: Yup.string()
                    .required('Message is required')
                    .test(
                        'no-leading-space',
                        'Message should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    ),
            })}
            onSubmit={(values, { setSubmitting }) => {
                const recaptchaValue = recaptchaRef.current.getValue();
                if (!recaptchaValue) {
                    toast.error('Please complete the Recaptcha validation');
                    return;
                }

                const id = toast.loading('Submitting');

                fetch('/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: encode({
                        'form-name': 'enquiry',
                        ...values,
                        'g-recaptcha-response': recaptchaValue,
                    }),
                })
                    .then(() => {
                        closeContact();
                        toast.success(
                            " We appreciate your interest and the time you've taken to contact us. Your message has been received, and we will get back to you as soon as possible.",
                            { id }
                        );
                    })
                    .catch((error) => {
                        setSubmitting(false);
                        toast.error('Something went wrong', { id });
                    });
            }}
        >
            {(formik) => (
                <form
                    name='enquiry'
                    method='post'
                    onSubmit={formik.handleSubmit}
                >
                    <input type='hidden' name='form-name' value='enquiry' />
                    <Flex fontFamily='normal'>
                        <Col px='2' mt={{ xs: '4', md: '3' }} width='100%'>
                            <Label color='#6B7888' display='block' py='2'>
                                Name<Span color='red'>*</Span>
                            </Label>
                            <Input
                                type='text'
                                name='Name'
                                placeholder='Name'
                                autocomplete='off'
                                value={formik.values['Name']}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormError formik={formik} fieldName='Name' />
                        </Col>
                        <Col px='2' mt={{ xs: '4', md: '3' }} width='100%'>
                            <Label
                                color='#6B7888'
                                pt='2'
                                display='block'
                                py='2'
                            >
                                Organisation Name<Span color='red'>*</Span>
                            </Label>
                            <Input
                                type='text'
                                name='Organisation-Name'
                                placeholder='Organisation Name'
                                value={formik.values['Organisation-Name']}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormError
                                formik={formik}
                                fieldName='Organisation-Name'
                            />
                        </Col>

                        <Col
                            px='2'
                            mt={{ xs: '4', md: '3' }}
                            width={{ xs: '100%', md: '50%' }}
                        >
                            <Label color='#6B7888' display='block' py='2'>
                                Contact Number<Span color='red'>*</Span>
                            </Label>
                            <PhoneInputWithCountrySelect
                                name='Contact-Number'
                                placeholder='Contact Number'
                                value={formik.values['Contact-Number']}
                                defaultCountry='IN'
                                addInternationalOption={false}
                                focusInputOnCountrySelection={false}
                                countryOptionsOrder={[
                                    'IN',
                                    'GB',
                                    'AE',
                                    '|',
                                    '...',
                                ]}
                                onChange={(value) =>
                                    formik.setFieldValue(
                                        'Contact-Number',
                                        value
                                    )
                                }
                                onBlur={formik.handleBlur}
                            />
                            <FormError
                                formik={formik}
                                fieldName='Contact-Number'
                            />
                        </Col>
                        <Col
                            px='2'
                            mt={{ xs: '4', md: '3' }}
                            width={{ xs: '100%', md: '50%' }}
                        >
                            <Label color='#6B7888' display='block' py='2'>
                                E-mail<Span color='red'>*</Span>
                            </Label>
                            <Input
                                type='email'
                                name='Email'
                                placeholder='E-mail'
                                value={formik.values['Email']}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormError formik={formik} fieldName='Email' />
                        </Col>
                        <Col px='2' mt={{ xs: '4', md: '3' }} width='100%'>
                            <Label color='#6B7888' display='block' py='2'>
                                Country / Region<Span color='red'>*</Span>
                            </Label>
                            <Input
                                name='Country'
                                placeholder='Country / Region'
                                value={formik.values['Country']}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormError formik={formik} fieldName='Country' />
                        </Col>
                        <Col px='2' mt={{ xs: '4', md: '3' }} width='100%'>
                            <Label color='#6B7888' display='block' py='2'>
                                Your Message<Span color='red'>*</Span>
                            </Label>
                            <Textarea
                                name='Message'
                                placeholder='Message'
                                value={formik.values['Message']}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                height='120px'
                            />
                            <FormError formik={formik} fieldName='Message' />
                        </Col>
                        <Flex width='100%' center mt='5'>
                            <Recaptcha
                                ref={recaptchaRef}
                                sitekey={RECAPTCHA_KEY}
                                size='normal'
                                id='recaptcha-google'
                                onChange={() => {
                                    setIsRecaptchaChecked(true);
                                }}
                                onErrored={() => {
                                    setIsRecaptchaChecked(false);
                                }}
                                onExpired={() => {
                                    setIsRecaptchaChecked(false);
                                }}
                            />
                        </Flex>

                        <Button
                            mt='7'
                            width='100%'
                            border='none'
                            background='#415B43'
                            fontSize='display6'
                            py='4'
                            fontWeight='500'
                            mx='auto'
                            borderRadius='6px'
                            color='#FFFFFF'
                            disabled={
                                formik.isSubmitting || !isRecaptchaChecked
                            }
                        >
                            Submit
                        </Button>
                        <Div mt='8'>
                            <Img
                                src={backpic}
                                width='100%'
                                alt='Screen featuring Garbage Surveillance camera'
                                title='Screen featuring Garbage Surveillance camera'
                            />
                        </Div>
                    </Flex>
                </form>
            )}
        </Formik>
    );
}

export default ContactNew;
const FormError = ({ formik, fieldName }) => {
    return (
        <>
            {formik.errors[fieldName] && formik.touched[fieldName] && (
                <Div fontSize='12px' color='red'>
                    {formik.errors[fieldName]}
                </Div>
            )}
        </>
    );
};
