import React from 'react';
import { Div, Flex, Img, Span } from '../../styles/styledElements';
import screen from '../../assets/images/screen.webp';
import profile from '../../assets/images/landImg.webp';
import { ReactComponent as Right } from '../../assets/icons/arrow-right.svg';
import user from '../../assets/images/user.webp';
import { ReactComponent as Start } from '../../assets/icons/start.svg';
import eye from '../../assets/images/eye.webp';
import move from '../../assets/images/move.webp';

function WorkModal() {
    return (
        <Flex
            py='8'
            pl={{ xl: '11', md: '7' }}
            pr='7'
            style={{
                backgroundImage: `url(${screen})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            display={{ md: 'flex', xs: 'none' }}
        >
            <ScreenCard />
        </Flex>
    );
}
const ScreenCard = () => {
    return (
        <Flex flexWrap='nowrap'>
            <Div width='29%'>
                <Div>
                    <Img
                        src={profile}
                        width='100%'
                        loading='lazy'
                        alt='A visual representation  that encapsulates the essence of our Garbage Detective platform.'
                        title='A visual representation  that encapsulates the essence of our Garbage Detective platform.'
                        style={{ width: '100%' }}
                    />
                </Div>
            </Div>
            <Flex
                width='67%'
                pt={{ md: '7', xl: '90px' }}
                pr={{ md: '7', xl: '90px' }}
                pl={{ md: '7', xl: '9' }}
                background='rgba(255, 255, 255, 0.10)'
                justifyContent='center'
                style={{
                    borderTopRightRadius: '25px',
                    borderBottomRightRadius: '25px',
                }}
            >
                <Div
                    width='100%'
                    fontSize='20px'
                    fontWeight='400'
                    style={{ backdropfilter: 'blur(98px)' }}
                    color='rgba(0, 0, 0, 0.77)'
                >
                    2 Videos Received
                    <Flex
                        mt='5'
                        pr='8'
                        borderRadius={{ md: '14px', lg: '16px' }}
                        width='95%'
                        style={{
                            backdropFilter: 'blur(98px)',
                            background: 'rgba(0, 0, 0, 0.20)',
                            boxShadow:
                                ' 0px -1px 1px 0px rgba(255, 255, 255, 0.35) inset, 2px 4px 4px -1px rgba(0, 0, 0, 0.25) inset',
                        }}
                    >
                        <Div
                            width={{ xxl: '80%' }}
                            py={{ md: '2', lg: '4' }}
                            color='rgba(255, 255, 255, 0.60)'
                            pl={{ lg: '7', md: '5' }}
                            pr={{ lg: '7', md: '5' }}
                            fontWeight='400'
                            fontSize={{ md: '14px', lg: '16px' }}
                        >
                            Search in Detected Videos
                        </Div>
                    </Flex>
                    <Flex mt={{ md: '6', lg: '8' }} width='100%'>
                        <Div width='26%'>
                            <Div
                                background='#FFFFFF'
                                p='4'
                                pt='3'
                                borderRadius='23px'
                            >
                                <Flex justifyContent='space-between' center>
                                    <Div width='10%'>
                                        <Img
                                            src={eye}
                                            width='100%'
                                            alt='An Eye'
                                            title='An Eye'
                                        />
                                    </Div>
                                    <Div
                                        width='85%'
                                        color='#979BA3'
                                        fontWeight='700'
                                        fontSize={{
                                            md: '.55rem',
                                            xl: '12px',
                                            lg: 'normal',
                                        }}
                                    >
                                        Cameras
                                    </Div>
                                </Flex>

                                <Div
                                    fontSize={{
                                        md: 'normal',
                                        lg: 'display3',
                                        xl: '28px',
                                    }}
                                    color='#000000'
                                    fontWeight='400'
                                    mt='1'
                                >
                                    470
                                    <Div
                                        color='#979BA3'
                                        mt='1'
                                        fontWeight='400'
                                        fontSize={{
                                            md: 'normal',
                                            lg: 'display6',
                                            xl: '12px',
                                        }}
                                    >
                                        May 12
                                    </Div>
                                    <Flex
                                        mt={{ lg: '4', md: '3' }}
                                        width={{ lg: '32px', md: '29px' }}
                                        height={{ lg: '32px', md: '29px' }}
                                        background='#1892FA'
                                        borderRadius='50%'
                                        center
                                        color='#ffffff'
                                    >
                                        <Right />
                                    </Flex>
                                </Div>
                            </Div>
                        </Div>
                        <Div
                            ml={{ md: '5%', lg: '10%', xxl: '14%' }}
                            width={{
                                xl: '39%',
                                lg: '42%',
                                md: '46%',
                            }}
                            color='#ffffff'
                        >
                            <Div
                                pl={{ lg: '5', md: '3', xl: '3' }}
                                pr={{ lg: '5', md: '3' }}
                                py={{ lg: '7', md: '5' }}
                                borderRadius={{ lg: '50px', md: '32px' }}
                                style={{
                                    border: '1px solid #252525',
                                    boxShadow:
                                        '0px 4px 4px 0px rgba(17, 17, 17, 0.39)',
                                    backdropFilter: 'blur(9px)',
                                    background:
                                        'linear-gradient(135deg, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0.55) 100%)',
                                }}
                            >
                                <Div>
                                    <Span
                                        fontSize={{ md: '15px', xxl: '17px' }}
                                        fontWeight='400'
                                    >
                                        New Video Detected{' '}
                                        <Span>
                                            <Img
                                                width={{ md: '12%', xl: '10%' }}
                                                src={move}
                                                alt='An alert identifying Console'
                                                title='An alert identifying Console'
                                            />
                                        </Span>
                                    </Span>
                                </Div>

                                <Div
                                    color='#C1C1C1'
                                    mt='3'
                                    fontSize='13px'
                                    fontWeight='400'
                                >
                                    01:00PM - 02:00PM
                                </Div>
                                <Div
                                    mt='4'
                                    fontSize={{ md: '15px', lg: '17px' }}
                                    fontWeight='400'
                                >
                                    Garden Area
                                </Div>
                                <Flex
                                    justifyContent='space-between'
                                    mt={{ md: '3', lg: '5' }}
                                    style={{ alignItems: 'center' }}
                                >
                                    <Div
                                        width='20%'
                                        color='#C1C1C1'
                                        fontSize={{ md: '15px', lg: '17px' }}
                                        fontWeight='400'
                                    >
                                        Share
                                    </Div>
                                    <Div width='40%'>
                                        <Img
                                            src={user}
                                            width='100%'
                                            alt='Added Users '
                                            title='Added Users '
                                        />
                                    </Div>
                                </Flex>
                            </Div>
                        </Div>
                        <Div
                            width={{
                                xxl: '16%',
                                xl: '17%',
                                lg: '20%',
                                md: '23%',
                            }}
                            ml='auto'
                            pl={{ md: '2', lg: '0' }}
                        >
                            <Flex
                                background='#FFECF3'
                                alignItems='center'
                                borderRadius='5px'
                                px='3'
                            >
                                <Div width='20%'>
                                    <Start />
                                </Div>
                                <Div
                                    width='80%'
                                    fontSize={{ md: '10px', lg: 'small' }}
                                    fontWeight='600'
                                    textAlign='center'
                                >
                                    6 Videos
                                </Div>
                            </Flex>
                        </Div>
                    </Flex>
                </Div>
            </Flex>
        </Flex>
    );
};
export default WorkModal;
