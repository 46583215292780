import React from 'react';
import { Div, Flex, H2, H3, Img } from '../../styles/styledElements';
import unique from '../../assets/images/unique.webp';
import { ReactComponent as Chip } from '../../assets/icons/Chip.svg';
import { ReactComponent as Screen } from '../../assets/icons/screen.svg';
import { ReactComponent as ChipMob } from '../../assets/icons/chipmob.svg';
import { ReactComponent as ScreenMob } from '../../assets/icons/screenmob.svg';

function UniqueFeature() {
    const cards = [
        {
            id: 0,

            value: (
                <FeatureCard
                    topic='High Accurate Decision'
                    content='Garbage Detective can detect even the smallest object illegally dumped,
            ensuring that fines are issued only when necessary within a single workflow.'
                    img={<Chip />}
                    imgmob={<ChipMob />}
                />
            ),
        },
        {
            id: 1,

            value: (
                <FeatureCard
                    topic='User-friendly Interface'
                    content='The web-based application is easy to install and manage. Garbage Detective can be accessed from any device or browser, and notifications can be delivered via SMS, email or mobile notifications.'
                    img={<Screen />}
                    imgmob={<ScreenMob />}
                />
            ),
        },
        {
            id: 2,

            value: (
                <FeatureCard
                    topic='Actionable Proofs'
                    content='Frequency of Conversion rate of crime to  punishment is high.'
                    img={<Screen />}
                    imgmob={<ScreenMob />}
                />
            ),
        },
        {
            id: 3,

            value: (
                <FeatureCard
                    topic='Alert and Analytics'
                    content='Garbage Detective provides real-time insights into the environmental impact of illegal dumping. These analytics can be used to direct garbage collection resources and reduce the risk of
            contamination.'
                    img={<Screen />}
                    imgmob={<ScreenMob />}
                />
            ),
        },
    ];
    return (
        <Flex
            flexWrap='nowrap'
            pt={{ lg: '6', md: '5', xs: '4' }}
            pl={{ xl: '183px', md: '8', xs: '5' }}
            pr={{ xl: '9', md: '7', xs: '5' }}
        >
            <Div
                width={{ lg: '57%', md: '60%', xs: '100%' }}
                borderLeft={{ md: '1px solid rgba(0, 0, 0, 0.26)', xs: 'none' }}
                borderRight={{
                    md: '1px solid rgba(0, 0, 0, 0.26)',
                    xs: 'none',
                }}
                pt={{ md: '8', xs: '0' }}
                pr={{ md: '3', xs: '0' }}
            >
                <Div pl={{ lg: '6', md: '4' }}>
                    <H2
                        fontSize={{
                            lg: '30px',
                            md: '25px',
                            xs: '22px',
                            sm: 'display3',
                        }}
                        fontWeight='700'
                        mb={{ md: '7', xs: '5' }}
                        lineHeight={{ xs: '33px', md: '1.5' }}
                    >
                        Unique Features of Garbage Detective
                    </H2>
                    {cards.map((data) => data.value)}
                </Div>
            </Div>
            <Flex
                width={{ lg: '43%', md: '40%' }}
                justifyContent='center'
                alignItems={{ xs: 'center', xl: 'start' }}
                display={{ md: 'flex', xs: 'none' }}
                pl='6'
                mt = {{xl: '4%'}}
            >
                <Div width='90%'>
                    <Img
                        src={unique}
                        width='100%'
                        loading='lazy'
                        alt='A Triple Exposure Image of a Gentleman '
                        title='A Triple Exposure Image of a Gentleman '
                    />
                </Div>
            </Flex>
        </Flex>
    );
}
const FeatureCard = ({ img, topic, content, imgmob }) => {
    return (
        <Flex flexWrap='nowrap' mb={{ md: '40px', xs: '13px' }}>
            <Flex
                width={{
                    lg: '10%',
                    md: '15%',
                }}
                justifyContent='center'
            >
                <Flex
                    width={{ xl: '48px', md: '45px', xs: '39px' }}
                    height={{ xl: '48px', md: '45px', xs: '39px' }}
                    center
                    display={{ md: 'flex', xs: 'none' }}
                    background='#252525'
                    borderRadius='50%'
                >
                    {img}
                </Flex>
                <Flex
                    width='39px'
                    height='39px'
                    center
                    display={{ xs: 'flex', md: 'none' }}
                    background='#252525'
                    borderRadius='50%'
                >
                    {imgmob}
                </Flex>
            </Flex>
            <Div width={{ lg: '90%', md: '80%' }} pl={{ md: '3', xs: '4' }}>
                <H3
                    fontSize={{
                        md: '20px',
                        xs: '16px',
                        sm: 'display5',
                    }}
                    fontWeight='700'
                    color='#111927'
                >
                    {topic}
                </H3>
                <Div
                    mt='3'
                    pb={{ md: '0', xs: '1' }}
                    pr={{ md: '2', lg: '4', xs: '4' }}
                    lineHeight='1.5'
                    fontWeight='400'
                    textAlign='justify'
                    color='#6B7280'
                    fontSize={{ md: '16px', xs: '14px' }}
                >
                    {content}
                </Div>
            </Div>
        </Flex>
    );
};

export default UniqueFeature;
