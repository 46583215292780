import React from 'react';
import { Div, Flex, H2, H3, Img } from '../../styles/styledElements';
import landing from '../../assets/images/landingPage.webp';
import Pie from '../../assets/icons/PieChart';
import Graph from '../../assets/icons/PresentationChartBar';
import Chart from '../../assets/icons/Chart';

function Unique() {
    const cards = [
        {
            id: 0,

            value: (
                <FeatureCard
                    topic='Traditional Garbage Bins'
                    content='Traditional garbage bins often have limited capacity and do not discriminate between legal and illegal dumping.'
                    img={<Pie />}
                />
            ),
        },
        {
            id: 1,

            value: (
                <FeatureCard
                    topic='Garbage Collection Services'
                    content='Garbage collection services offer convenience, but they may not always be able to collect all waste, especially in remote or
inaccessible areas.'
                    img={<Graph />}
                />
            ),
        },
        {
            id: 2,

            value: (
                <FeatureCard
                    topic='Surveillance Drones'
                    content='Surveillance drones can generate high-quality footage, but they are expensive, often require skilled operators and can be
intrusive.'
                    img={<Chart />}
                />
            ),
        },
    ];
    return (
        <Flex
            flexWrap='nowrap'
            mt={{ md: '6', xs: '3' }}
            pb={{ md: '90px', xs: '0' }}
            justifyContent='space-between'
            pl={{ xl: '9', md: '6', xs: '5' }}
            pr={{ xl: '178px', md: '7', xs: '5' }}
        >
            <Div
                width='42%'
                pt='93px'
                pb='5'
                display={{ md: 'flex', xs: 'none' }}
                style={{ justifyContent: 'center' }}
            >
                <Div width='54%'>
                    <Img
                        src={landing}
                        width='100%'
                        loading='lazy'
                        alt='Screen featuring the home Page of Garbage Detective mobile application.'
                        title='Screen featuring the home Page of Garbage Detective mobile application.'
                    />
                </Div>
            </Div>
            <Div
                width={{ md: '58%', xs: '100%' }}
                borderTop={{ md: 'none', xs: '1px solid rgba(0, 0, 0, 0.26)' }}
                borderBottom={{
                    md: 'none',
                    xs: '1px solid rgba(0, 0, 0, 0.26)',
                }}
                borderLeft={{ md: '1px solid rgba(0, 0, 0, 0.26)', xs: 'none' }}
                borderRight={{
                    md: '1px solid rgba(0, 0, 0, 0.26)',
                    xs: 'none',
                }}
                pt={{ md: '100px', xs: '5' }}
                pb={{ md: '6', xs: '5' }}
                pr={{ md: '3', xs: '0' }}
            >
                <Div pl={{ md: '5', xs: '0' }}>
                    <H2
                        fontSize={{
                            lg: '30px',
                            md: '24px',
                            xs: '22px',
                        }}
                        fontWeight='700'
                        lineHeight='1.5'
                    >
                        How Garbage Detective stacks up{' '}
                        <Div display={{ md: 'block', xs: 'none' }} />
                        against it's competition is...
                    </H2>
                    {cards.map((data) => data.value)}
                </Div>
            </Div>
        </Flex>
    );
}

const FeatureCard = ({ img, topic, content }) => {
    return (
        <Flex flexWrap='nowrap' mt={{ md: '40px', xs: '5' }}>
            <Div width={{ lg: '10%', md: '15%' }}>
                <Flex
                    width={{ xl: '48px', lg: '43px', xs: '41px' }}
                    height={{ xl: '48px', lg: '43px', xs: '41px' }}
                    center
                    background='#FFF'
                    borderRadius='50%'
                >
                    {img}
                </Flex>
            </Div>
            <Div width={{ lg: '90%', md: '85%' }} pl={{ md: '3', xs: '4' }}>
                <H3
                    fontSize={{
                        md: '20px',

                        xs: '16px',
                        sm: 'display5',
                    }}
                    fontWeight='700'
                    color='#111927'
                >
                    {topic}
                </H3>
                <Div
                    mt='3'
                    pr='4'
                    lineHeight='1.5'
                    color='#4B5663'
                    fontWeight='400'
                    fontSize={{
                        md: '16px',

                        xs: '14px',
                    }}
                >
                    {content}
                </Div>
            </Div>
        </Flex>
    );
};

export default Unique;
