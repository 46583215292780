import React from 'react';
import { useMediaType } from '../../components/Responsiver';

function Chart() {
    const { isMobile } = useMediaType();

    return (
        <svg
            width={isMobile ? '28' : '32'}
            height={isMobile ? '28' : '32'}
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.60039 3.2002C8.7517 3.2002 7.93777 3.53734 7.33765 4.13745C6.73753 4.73757 6.40039 5.5515 6.40039 6.4002V25.6002C6.40039 26.4489 6.73753 27.2628 7.33765 27.8629C7.93777 28.4631 8.7517 28.8002 9.60039 28.8002H22.4004C23.2491 28.8002 24.063 28.4631 24.6631 27.8629C25.2632 27.2628 25.6004 26.4489 25.6004 25.6002V11.8626C25.6002 11.014 25.2629 10.2002 24.6628 9.60019L19.2004 4.1378C18.6004 3.53764 17.7866 3.20038 16.938 3.2002H9.60039ZM12.8004 19.2002C12.8004 18.7758 12.6318 18.3689 12.3318 18.0688C12.0317 17.7688 11.6247 17.6002 11.2004 17.6002C10.776 17.6002 10.3691 17.7688 10.069 18.0688C9.76896 18.3689 9.60039 18.7758 9.60039 19.2002V24.0002C9.60039 24.4245 9.76896 24.8315 10.069 25.1316C10.3691 25.4316 10.776 25.6002 11.2004 25.6002C11.6247 25.6002 12.0317 25.4316 12.3318 25.1316C12.6318 24.8315 12.8004 24.4245 12.8004 24.0002V19.2002ZM16.0004 14.4002C16.4247 14.4002 16.8317 14.5688 17.1318 14.8688C17.4318 15.1689 17.6004 15.5758 17.6004 16.0002V24.0002C17.6004 24.4245 17.4318 24.8315 17.1318 25.1316C16.8317 25.4316 16.4247 25.6002 16.0004 25.6002C15.576 25.6002 15.1691 25.4316 14.869 25.1316C14.569 24.8315 14.4004 24.4245 14.4004 24.0002V16.0002C14.4004 15.5758 14.569 15.1689 14.869 14.8688C15.1691 14.5688 15.576 14.4002 16.0004 14.4002ZM22.4004 12.8002C22.4004 12.3758 22.2318 11.9689 21.9318 11.6688C21.6317 11.3688 21.2247 11.2002 20.8004 11.2002C20.376 11.2002 19.9691 11.3688 19.669 11.6688C19.369 11.9689 19.2004 12.3758 19.2004 12.8002V24.0002C19.2004 24.4245 19.369 24.8315 19.669 25.1316C19.9691 25.4316 20.376 25.6002 20.8004 25.6002C21.2247 25.6002 21.6317 25.4316 21.9318 25.1316C22.2318 24.8315 22.4004 24.4245 22.4004 24.0002V12.8002Z'
                fill='#6B7280'
            />
        </svg>
    );
}

export default Chart;
