import React from 'react';
import { Div, Flex, H2, Link, Span } from '../styles/styledElements';
import { ReactComponent as GDlogo } from '../assets/icons/GD_LOGO.svg';

function Footer() {
    return (
        <Flex
            mt={{ lg: '4', xs: '4' }}
            fontSize={{ lg: '14px', xs: '11px' }}
            fontWeight="400"
            justifyContent={{ xs: 'center', lg: 'start' }}
            alignItems="center"
        >
            <Div
                width={{ lg: '7%', xs: '10%', xxl: '6%' }}
                display={{ lg: 'block', xs: 'none' }}
            >
                <GDlogo />
            </Div>
            <Div width={'93%'}>
                <Div
                    fontSize="20px"
                    fontWeight="700"
                    display={{ xs: 'block', lg: 'none' }}
                    textAlign="center"
                >
                    Garbage Detective
                </Div>
                <Flex mt="10px" justifyContent="center">
                    <Span
                        display="inline-block"
                        fontSize={{ lg: '14px', xs: '9px' }}
                        fontWeight="400"
                        width={{ xs: '100%', lg: 'auto' }}
                        textAlign="center"
                    >
                        Copyright © 2023 Garbage Detective. All rights reserved
                    </Span>
                    <Link
                        to="/privacy-policy"
                        mt={{ xs: '4', lg: '0' }}
                        pr={{ xs: '3', lg: '0' }}
                        borderRight={{ xs: '1px solid', lg: ' none' }}
                        color={{ xs: '#86868B', lg: 'initial' }}
                        textAlign="center"
                        marginLeft={{
                            lg: 'auto',
                        }}
                    >
                        Privacy Policy
                    </Link>
                    <Link
                        to="/terms"
                        ml={{ xs: '3', lg: '7', xl: '9' }}
                        mt={{ xs: '4', lg: '0' }}
                        color={{ xs: '#86868B', lg: 'initial' }}
                        textAlign="center"
                    >
                        Terms and Conditions
                    </Link>
                </Flex>
            </Div>
        </Flex>
    );
}
export default Footer;
