import React from 'react';
import { Div } from '../../styles/styledElements';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

function TermsAndC({ openContact }) {
    return (
        <Div>
            <Nav
                openContact={openContact}
                px={{ xs: '6', md: '10' }}
                background='rgba(195, 214, 244, 0.41)'
            />
            <Div px={{ xs: '6', md: '10' }}>
                <h1>Terms and Conditions</h1>

                <p>
                    <strong>Last updated:</strong> 22-August-2023
                </p>

                <p>
                    Welcome to Garbage Detective! These terms and conditions
                    outline the rules and regulations for the use of our website
                    and services. By accessing or using our website, you agree
                    to be bound by these terms and conditions.
                </p>

                <h2>1. Data Protection and Privacy</h2>

                <h3>1.1 Data Collection:</h3>
                <p>
                    We collect and process personal data for the purpose of
                    providing our services and enhancing your user experience.
                    The data collected may include but is not limited to user
                    identification, location, and usage patterns.
                </p>

                <h3>1.2 Lawful Basis:</h3>
                <p>
                    We process personal data on the basis of your consent,
                    contract performance, or legitimate interests, as required
                    by the General Data Protection Regulation (GDPR).
                </p>

                <h3>1.3 Data Controller:</h3>
                <p>
                    Roade Labs Pvt Ltd is the data controller responsible for
                    the collection, processing, and protection of personal data.
                </p>

                <h3>1.4 Data Processing:</h3>
                <p>
                    We may share data with third-party service providers to
                    enable the operation and improvement of our services. Data
                    is processed in accordance with GDPR requirements.
                </p>

                <h3>1.5 User Rights:</h3>
                <p>
                    You have the right to access, rectify, and erase your
                    personal data. You can exercise these rights by contacting
                    us at{' '}
                    <a href='mailto:support@garbagedetective.com'>
                        support@garbagedetective.com
                    </a>
                    .
                </p>

                <h2>2. Security</h2>

                <h3>2.1 Data Security:</h3>
                <p>
                    We take appropriate technical and organizational measures to
                    ensure the security of personal data and to protect it
                    against unauthorized or unlawful processing and accidental
                    loss, destruction, or damage.
                </p>

                <h2>3. Third Parties</h2>

                <h3>3.1 Third-Party Links:</h3>
                <p>
                    Our website may contain links to third-party websites. We
                    are not responsible for their privacy practices or content.
                    We recommend reviewing their privacy policies.
                </p>

                <h2>4. Changes to Terms and Conditions</h2>

                <h3>4.1 Modification:</h3>
                <p>
                    We reserve the right to modify or replace these terms and
                    conditions at any time. Updates will be posted on this page.
                </p>

                <h2>5. Contact Us</h2>

                <h3>5.1 Questions:</h3>
                <p>
                    If you have any questions about our terms and conditions or
                    data protection practices, please contact us at{' '}
                    <a href='mailto:support@garbagedetective.com'>
                        support@garbagedetective.com
                    </a>
                    .
                </p>

                <h2>6. Governing Law</h2>

                <h3>6.1 Jurisdiction:</h3>
                <p>
                    These terms and conditions shall be governed by and
                    construed in accordance with the laws of INDIA. Any disputes
                    shall be subject to the exclusive jurisdiction of the courts
                    in Cochin, INDIA.
                </p>

                <p>
                    By using our website and services, you acknowledge that you
                    have read, understood, and agreed to these terms and
                    conditions.
                </p>
            </Div>
            <Div
                background='rgba(195, 214, 244, 0.41)'
                px={{ xs: '6', md: '10' }}
                pt={{ md: '7', xs: '4' }}
                pb={{ md: '7', xs: '6' }}
                mt={{ md: '8', xs: '6' }}
            >
                <Footer />
            </Div>
        </Div>
    );
}

export default TermsAndC;
