import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, Div } from '../styles/styledElements';

import { ReactComponent as Close } from '../assets/icons/close.svg';

const backdropProps = {
    position: 'fixed',
    zIndex: '1005',
    top: '0',
    left: '0',
    outline: '0',
    bg: '#000000DD',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    id: 'backdrop',
};
const bodyProps = {
    backgroundClip: 'padding-box',
    outline: '0',
    pointerEvents: 'auto',
    position: 'relative',
    width: '100%',
    p: { xs: '4', md: '5' },
};
const layoutProps = {
    pointerEvents: 'none',
    position: 'relative',
    border: '1px solid #E2E2E2',
    mx: 'auto',
    my: '2rem',
    bg: 'white',
    borderRadius: '7',
};
const headerProps = {
    p: { xs: '4', md: '5' },
    pb: { md: '3' },
    position: 'relative',
};

function Modal({
    children,
    show = false,
    onClose = () => {},
    size = '100%',
    heading,
    backdrop = '',
    close = true,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [el] = useState(document.createElement('div'));

    useEffect(() => {
        setIsOpen(show);
    }, [show]);

    useEffect(() => {
        document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        };
    }, [el]);

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    const onBackDropClick = (e) => {
        if (backdrop !== 'static') {
            if (e.target.id === 'backdrop') {
                handleClose();
            }
        }
    };

    // switch (size) {
    //     case 'sm':
    //         layoutProps.width = '400px';
    //         break;
    //     case 'md':
    //         layoutProps.width = '600px';
    //         break;
    //     case 'lg':
    //         layoutProps.width = '800px';
    //         break;

    //     default:
    //         layoutProps.width = '600px';
    //         break;
    // }

    return (
        <>
            {isOpen &&
                createPortal(
                    <Div {...backdropProps} onClick={onBackDropClick}>
                        <Div {...layoutProps} width={size}>
                            {heading && (
                                <Div {...headerProps}>
                                    {heading}
                                    {close !== false && (
                                        <Button
                                            width='35px'
                                            height='35px'
                                            borderRadius='50%'
                                            border='none'
                                            background='#EFF1F4'
                                            onClick={handleClose}
                                            position='absolute'
                                            right='20px'
                                            top='25%'
                                        >
                                            <Close />
                                        </Button>
                                    )}
                                </Div>
                            )}
                            <Div {...bodyProps}>{children}</Div>
                        </Div>
                    </Div>,
                    el
                )}
        </>
    );
}

export default Modal;
