import React, { Children } from 'react';
import { Helmet } from 'react-helmet';

function SEO({ title, description, name, type, keywords, link, children }) {
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <link rel='canonical' href={link} />

            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            {/* End standard metadata tags */}

            {/* Facebook tags */}
            {/* <meta property='og:type' content={type} /> */}
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            {/* End Facebook tags */}

            {/* Twitter tags */}
            <meta name='twitter:creator' content={name} />
            {/* <meta name='twitter:card' content={type} /> */}
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            {/* End Twitter tags */}
            {children}
        </Helmet>
    );
}

export default SEO;
