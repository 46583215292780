import React from 'react';
import Hero from './Hero';
import Usage from './Usage';
import UniqueFeature from './UniqueFeature';
import Unique from './Unique';
import WorkModal from './WorkModal';
import HowWork from './HowWork';
import Benefits from './Benefits';
import Testimonials from '../landing/Testimonials';
import Technology from './Technology';
import End from './End';
import { Div, Flex } from '../../styles/styledElements';
import Footer from '../../components/Footer';
import back from '../../assets/images/back.webp';
import { useMediaType } from '../../components/Responsiver';
import SEO from '../../components/SEO';

function Index({ openContact }) {
    const { isMobile } = useMediaType();

    return (
        <>
            <SEO
                title="Garbage Detective: AI-Powered Illegal Garbage Disposal Detection System"
                description="AI detects illegal garbage disposal, protects environment & community with advanced tech."
                name="Garbage Detection"
                keywords="AI garbage detection,Illegal waste disposal solution,Garbage detection technology,
                AI waste management,Environmental monitoring,Smart waste detection,Garbage disposal 
                enforcement,Automated waste detection,Illegal dumping prevention,AI-powered cleanliness,Sustainable 
                waste management,Trash detection system,Waste disposal monitoring,Clean city initiative,Garbage surveillance
                 technology,Urban waste detection,AI for environmental protection,Waste disposal enforcement,Smarter waste 
                 solutions,Waste analytics and detection,AI garbage detection system,Illegal waste disposal detection,Waste 
                 management AI,Environmental monitoring solution,Smart trash detection,AI-powered waste enforcement,Advanced garbage
                  detection,,Waste surveillance technology,Greener environment solutions,Smart city waste management,Automated waste 
                  monitoring,Waste disposal violation detection,Sustainable waste solutions,AI-driven garbage detection,Urban waste
                   management,Intelligent waste tracking,Illegal litter detection,Remote waste surveillance"
                type="article"
                link="https://garbagedetective.com/"
            >
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'http://schema.org',
                        '@type': 'SoftwareApplication',
                        name: 'Garbage Detective',
                        image: 'https://garbagedetective.com/static/media/landingPage.87de0b3ed250d122b1de.webp',
                        url: 'https://garbagedetective.com/',
                        author: {
                            '@type': 'Person',
                            name: 'RoadE Labs',
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: 'RoadE Labs',
                        },
                        applicationCategory: 'Business Application',
                        operatingSystem: ['Android', 'iOS', 'Cross-platform'],
                    })}
                </script>
            </SEO>
            <Div width="100%" background={{ md: 'none', xs: '#d5e5ff' }}>
                <Hero openContact={openContact} />
                <Usage />
                <Div background={{ md: '#c0d4f5', xs: '#d5e5ff' }}>
                    <UniqueFeature />
                    <Unique />
                </Div>
                <WorkModal />
                {isMobile ? (
                    <Div background="#d5e5ff">
                        <HowWork />
                        <Benefits />
                        {/* <Testimonials /> */}
                    </Div>
                ) : (
                    <Flex
                        style={{
                            backgroundImage: `url(${back})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <Div px={{ xl: '9', md: '8' }}>
                            <HowWork />
                            <Benefits />
                            {/* <Testimonials /> */}
                        </Div>
                    </Flex>
                )}

                {isMobile ? (
                    <Div background="#d5e5ff">
                        <Technology />
                        <End openContact={openContact} />
                    </Div>
                ) : (
                    <Flex
                        pt="8"
                        pb="7"
                        style={{
                            backgroundImage: `url(${back})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <Technology />
                        <End openContact={openContact} />
                    </Flex>
                )}
                <Div
                    background="rgba(195, 214, 244, 0.41)"
                    px={{ xxl: '11', lg: '9', md: '8', xs: '0' }}
                    pt={{ md: '7', xs: '4' }}
                    pb={{ md: '7', xs: '6' }}
                    mt={{ md: '0', xs: '4' }}
                >
                    <Footer />
                </Div>
            </Div>
        </>
    );
}

export default Index;
