import React from 'react';
import { Div } from './styles/styledElements';
import Footer from './components/Footer';
import Nav from './components/Nav';

function Layout({ children, openContact }) {
    return (
        <Div>
            {/* <Nav openContact={openContact} /> */}

            {children}
            {/* <Footer openContact={openContact} /> */}
        </Div>
    );
}

export default Layout;
