import React from 'react';
import { Div, Flex, H2, Span } from '../../styles/styledElements';
import Idea from '../../assets/icons/Idea';
import Presentation from '../../assets/icons/Presentation';
import Eye from '../../assets/icons/Eye';

function Features() {
    const cards = [
        {
            id: 0,
            value: (
                <FeatureCard
                    topic={
                        <Span>
                            Real-time Detection &
                            <br /> Prevention
                        </Span>
                    }
                    content='Garbage Detective is a unique edge processing image technology
                that uses cameras, AI, and machine learning to monitor for
                illegal garbage dumping.'
                    icon={<Idea />}
                />
            ),
        },
        {
            id: 1,
            value: (
                <FeatureCard
                    topic={
                        <Span>
                            The Environmentally
                            <br /> Friendly Solution
                        </Span>
                    }
                    content='Garbage Detective offers a sustainable solution to tackle the global environmental challenge of improper garbage disposal.'
                    icon={<Presentation />}
                />
            ),
        },
        {
            id: 2,
            value: (
                <FeatureCard
                    topic='Global Impact'
                    content='Garbage Detective has a positive impact on the environment and society at large, as it encourages accountability and helps enforce regulatory standards, leading to a cleaner world.'
                    icon={<Eye />}
                />
            ),
        },
    ];

    return (
        <Div px={{ md: '0', xs: '5' }}>
            <Flex
                width='100%'
                height='100%'
                justifyContent='space-around'
                borderTop={{ xs: '2px solid rgba(0, 0, 0, 0.18)', md: 'none' }}
                pt={{ xs: '4', md: '0' }}
            >
                {cards.map((data) => (
                    <Div
                        key = {data.id}
                        mb={{ xs: '4', md: 'none' }}
                        width={{ md: '32%', lg: '28%', xs: '100%', xl: '30%' }}
                    >
                        {data.value}
                    </Div>
                ))}
            </Flex>
        </Div>
    );
}

const FeatureCard = ({ topic, icon, content }) => {
    return (
        <Div
            pr={{ xs: '6', md: '4', xl: '5' }}
            pl={{ xs: '5', md: '4', xl: '5' }}
            pt={{ xs: '5', md: '4', xl: '6' }}
            pb={{ xs: '6', md: '4', xl: '5' }}
            height='100%'
            background='#FFFFFF'
            borderRadius={{ lg: '35px', md: '30px', xs: '30px' }}
            style={{
                backdropFilter: 'blur(40px)',
                boxShadow: '0px 4px 10px 1px rgba(0, 0, 0, 0.08)',
            }}
            textAlign='justify'
        >
            <Flex
                height={{ lg: '48px', md: '38px', xs: '48px' }}
                width={{ lg: '48px', md: '38px', xs: '48px' }}
                background='#F3F4F6'
                borderRadius='50%'
                center
            >
                {icon}
            </Flex>
            <H2
                color='#111927'
                fontSize={{ xs: '20px', md: '14px', lg: '20px' }}
                fontWeight='700'
                mt={{ xs: '4', md: '2', lg: '4' }}
                lineHeight='28px'
            >
                {topic}
            </H2>
            <Div
                fontSize={{
                    lg: '16px',
                    md: '12px',
                    xs: '15px',
                }}
                color='#6B7280'
                fontWeight='400'
                lineHeight={{ md: '1.5', xs: '24px' }}
                mt={{xs:'4',md:'3'}}
            >
                {content}
            </Div>
        </Div>
    );
};

export default Features;
