import React from 'react';
import { Div, Flex, H2, Img, Span } from '../../styles/styledElements';
import sample from '../../assets/images/Modal.webp';
import sampleMob from '../../assets/images/Modalmob.webp';

function Technology() {
    return (
        <Div>
            <Flex
                color='#000000'
                fontWeight='600'
                display={{ xs: 'flex', md: 'none' }}
                pt='7'
                style={{ position: 'relative' }}
            >
                <Img
                    src={sampleMob}
                    width='100%'
                    loading='lazy'
                    alt='Screen featuring that your need is always in '
                    title='Screen featuring that your need is always in '
                />

                <H2
                    pl='6'
                    mt={{ xs: '-33px', sm: '-45px' }}
                    style={{ position: 'absolute' }}
                    fontSize={{ xs: '28px', sm: 'display1' }}
                    fontWeight='400'
                >
                    Easy <br />
                    <Span color='#FFFFFF'>
                        to <br />
                        Fix
                    </Span>
                </H2>
            </Flex>

            <Flex
                center
                display={{ xs: 'none', md: 'flex' }}
                px={{ md: '8', lg: '9', xl: '220px' }}
            >
                <Img
                    src={sample}
                    width='100%'
                    loading='lazy'
                    alt='Screen featuring that your need is always in '
                    title='Screen featuring that your need is always in '
                />
            </Flex>
            <Div px={{ xl: '11', lg: '9', md: '8', xs: '6' }}>
                <Div
                    mt='4'
                    width={{ xs: '100%', md: '75%', xl: '70%', xxl: '61%' }}
                    pb={{ md: '8', xs: '6' }}
                    borderRight={{
                        md: '1px solid rgba(0, 0, 0, 0.26)',
                        xs: 'none',
                    }}
                    borderLeft={{
                        md: '1px solid rgba(0, 0, 0, 0.26)',
                        xs: 'none',
                    }}
                >
                    <Div pt={{ lg: '8', md: '6' }} px={{ md: '5' }}>
                        <H2
                            fontSize={{ md: '26px', lg: '30px' }}
                            fontWeight='700'
                            display={{ md: 'block', xs: 'none' }}
                            lineHeight={{ md: '35px', lg: '45px' }}
                        >
                            Technology will drive the future
                            <br /> of Waste Management
                        </H2>
                        <H2
                            fontSize={{ xs: '20px', sm: 'display3' }}
                            fontWeight='700'
                            mt='5'
                            textAlign='start'
                            lineHeight='30px'
                            display={{ md: 'none', xs: 'block' }}
                        >
                            Technology will drive the future of Waste Management
                        </H2>
                        <Div
                            mt={{ md: '3', xs: '2' }}
                            pr={{ md: '0', xs: '4' }}
                            pb={{ md: '0', xs: '5' }}
                            fontSize={{
                                md: '18px',
                                xs: '14px',
                            }}
                            lineHeight={{
                                xs: '1.7',
                                md: '1.7',
                                xl: '27px',
                            }}
                            textAlign='justify'
                            color='#4B5663'
                            fontWeight='400'
                            borderBottom={{
                                md: 'none',
                                xs: '1px solid rgba(0, 0, 0, 0.26)',
                            }}
                        >
                            Garbage detection AI refers to the use of artificial
                            intelligence and machine learning algorithms to
                            detect and classify different types of garbage and
                            waste materials. This technology has numerous
                            applications in waste management and environmental
                            conservation, including sorting and recycling of
                            waste materials, reducing landfill waste, and
                            identifying and addressing sources of pollution.
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}

export default Technology;
