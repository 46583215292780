import React from 'react';
import { Div, Flex, H2, H3, Img } from '../../styles/styledElements';
import benefits from '../../assets/images/benefits.webp';
import Health from '../../assets/images/health.webp';
import Cost from '../../assets/images/cost.webp';
import Nature from '../../assets/images/enviornment.webp';
import DropDown from './DropDown';
import { useMediaType } from '../../components/Responsiver';

function Benefits() {
    const { isMobile } = useMediaType();

    const cards = [
        {
            id: 0,

            topic: 'Improved Public Health',
            content:
                'Illegal dumping negatively affects public health by attracting pests and vermin, and is often linked to hazardous waste.',
            img: Health,
            title: 'Screen featuring Text Health',
        },
        {
            id: 1,

            topic: 'Cost-effective Solution',
            content:
                'Garbage Detective reduces the operational and maintenance costs associated with traditional surveillance methods. It also provides bonefide recorded timestamped feeds for legal actions.',
            img: Cost,
            title: 'An individual managing his personal Finances',
        },
        {
            id: 2,

            topic: 'A clearer Environment',
            content:
                'Illegal dumping damages the environment, posing a threat to the local fauna and flora, and polluting the air, water, and soil. Garbage Detective helps to mitigate this impact.',
            img: Nature,
            title: 'Screen featuring Nature.',
        },
    ];
    return (
        <Div
            mt={{ md: '0', xs: '6' }}
            pl={{ xs: '6', md: '0', lg: '7', xl: '80px' }}
            pb='5'
            pt={{ md: '5', xs: '0' }}
            pr={{ xs: '6', md: '3', xl: '4' }}
        >
            <H2
                fontSize={{ md: '30px', xs: '22px' }}
                fontWeight='700'
                mb='3'
                pl={{ md: '5', xs: '0' }}
            >
                Benefits
            </H2>
            {isMobile ? (
                <>
                    {cards.map((card) => {
                        return (
                            <DropDown
                                key={card.id}
                                topic={card.topic}
                                content={card.content}
                            />
                        );
                    })}
                </>
            ) : (
                <Flex flexWrap='nowrap' mt='6'>
                    <Flex
                        width='58%'
                        pt='8'
                        pl='6'
                        pr='5'
                        style={{
                            flexDirection: 'column',
                        }}
                        borderLeft='1px solid rgba(0, 0, 0, 0.26)'
                        borderRight='1px solid rgba(0, 0, 0, 0.26)'
                    >
                        {cards.map((data) => (
                            <Div pb='7'>
                                <FeatureCard
                                    key={data.id}
                                    img={data.img}
                                    topic={data.topic}
                                    content={data.content}
                                    title={data.title}
                                />
                            </Div>
                        ))}
                    </Flex>

                    <Flex width='42%' pl='4' pr='5'>
                        <Div width='100%'>
                            <Img
                                src={benefits}
                                width='100%'
                                loading='lazy'
                                alt='Screen featuring that your need is always in '
                                title='Screen featuring that your need is always in '
                            />
                        </Div>
                    </Flex>
                </Flex>
            )}
        </Div>
    );
}
const FeatureCard = ({ img, topic, content, title }) => {
    return (
        <Flex flexWrap='nowrap'>
            <Flex width={{ xl: '10%', md: '13%' }} justifyContent='center'>
                <Flex width='31px' height='47px' center>
                    <Img src={img} width='100%' alt={title} title={title} />
                </Flex>
            </Flex>
            <Div width={{ xl: '90%', md: '87%' }} pl='1'>
                <H3 fontSize='20px' fontWeight={{ md: '700', xs: '400' }}>
                    {topic}
                </H3>
                <Div
                    mt='3'
                    color='#6B7280'
                    lineHeight='1.5'
                    fontSize='16px'
                    fontWeight='400'
                >
                    {content}
                </Div>
            </Div>
        </Flex>
    );
};

export default Benefits;
