import React from 'react';
import { Button, Div, Flex, Link } from '../styles/styledElements';
import { ReactComponent as GDlogo } from '../assets/icons/GD_LOGO.svg';

function Nav({ openContact, ...rest }) {
    return (
        <Div {...rest}>
            <Flex
                pt={{ xs: '8', md: '6', lg: '7' }}
                pb={{ xs: '4', md: '3' }}
                flexWrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid rgba(0, 0, 0, 0.26)"
            >
                <Div width={{ xl: '30%', lg: '35%', md: '40%', xs: '50%' }}>
                    <Link
                        to="/"
                        color="#000"
                        fontSize={{
                            md: '19px',
                            sm: 'display6',
                            xs: '15px',
                        }}
                        fontWeight="700"
                        display="flex"
                        alignItems="center"
                    >
                        <GDlogo
                            style={{ marginRight: '10px', width: '113px' }}
                        />
                        {/* <span style={{ fontSize: 'inherit', marginTop: '34px' }}>Garbage Detective</span> */}
                    </Link>
                </Div>
                <Flex
                    width={{ xl: '40%', md: '45%', xs: '50%' }}
                    // alignItems='center'
                >
                    {/* <Div
                        width='40%'
                        fontSize='display5'
                        textAlign='center'
                        display={{ md: 'block', xs: 'none' }}
                    >
                        Overview
                    </Div> */}
                    <Flex width={{ md: '60%', xs: '100%' }} ml="auto">
                        <Button
                            background="#415B43"
                            color="#ffffff"
                            py={{ xs: '4', md: '3', lg: '4', xl: '18px' }}
                            px={{ xs: '4', md: '3', lg: '4', xl: '5' }}
                            fontSize="14px"
                            borderRadius="25px"
                            border="none"
                            ml="auto"
                            fontWeight="400"
                            onClick={openContact}
                        >
                            Get a Free Demo
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Div>
    );
}

export default Nav;
