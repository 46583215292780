import React from 'react';
import { Div, Flex, H3, Img } from '../../styles/styledElements';
import { ReactComponent as Avatar } from '../../assets/icons/Group-Avatar.svg';
import need from '../../assets/images/question.webp';
import needMob from '../../assets/images/questionMob.webp';
import video1 from '../../assets/video/samplededection1.mp4';
import mobVideo1 from '../../assets/video/samplededection1Mob.mp4';
import video2 from '../../assets/video/samplededection2.mp4';
import mobVideo2 from '../../assets/video/samplededection2Mob.mp4';
import styled from 'styled-components';
const BackDrop = styled(Flex)`
    backdrop-filter: blur(67.95500183105469px);
`;
function Usage() {
    return (
        <>
            <Div display={{ md: 'none', xs: 'block' }} mt='4' px='3'>
                <Flex style={{ justifyContent: 'space-around' }}>
                    <Div width='45%'>
                        <video
                            width='100%'
                            autoPlay
                            muted
                            loop
                            src={mobVideo2}
                            type='video/mp4'
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                    </Div>
                    <Div width='45%'>
                        <video
                            width='100%'
                            autoPlay
                            muted
                            loop
                            src={mobVideo1}
                            type='video/mp4'
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                    </Div>
                </Flex>
                <Flex center>
                    <Div
                        width='85%'
                        pb='5'
                        borderBottom='1px solid rgba(0, 0, 0, 0.26)'
                    >
                        <Img
                            src={needMob}
                            width='100%'
                            loading='lazy'
                            alt="A lady pointing towards us , asking 'Do you, too, grapple with this  challenge?"
                            title="A lady pointing towards us , asking 'Do you, too, grapple with this  challenge?"
                        />
                    </Div>
                </Flex>
            </Div>
            <Flex flexWrap='nowrap' display={{ md: 'flex', xs: 'none' }}>
                <Div width='40%' style={{ position: 'relative' }}>
                    <video
                        width='100%'
                        autoPlay
                        muted
                        loop
                        src={video2}
                        type='video/mp4'
                        style={{ display: 'block' }}
                    />

                    <Div
                        style={{
                            position: 'absolute',
                            top: '0%',
                        }}
                    >
                        <GifCard content='🔔 Be Notified in a Second' />
                    </Div>
                </Div>
                <Flex width='20%'>
                    <Div position='relative' width='100%' overflow='hidden'>
                        <Img
                            src={need}
                            width='100%'
                            alt="A lady pointing towards us , asking 'Do you, too, grapple with this  challenge?"
                            title="A lady pointing towards us , asking 'Do you, too, grapple with this  challenge?"
                            style={{
                                position: 'absolute',
                                top: '-5%',
                            }}
                        />
                    </Div>
                </Flex>
                <Div width='40%' style={{ position: 'relative' }}>
                    <video
                        width='100%'
                        autoPlay
                        style={{ display: 'block' }}
                        muted
                        loop
                        src={video1}
                        type='video/mp4'
                    />

                    <Div style={{ position: 'absolute', top: '0%' }}>
                        <GifCard content='It’s time to react' />
                    </Div>
                </Div>
            </Flex>
        </>
    );
}

const GifCard = ({ content }) => {
    return (
        <BackDrop
            mt='2'
            ml='3'
            py='3'
            px='4'
            border='2px solid #969696'
            width='fit-content'
            borderRadius='24px'
            color='#ffffff'
            flexWrap='nowrap'
            alignItems='center'
            background={
                'linear-gradient(0deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0.66) 100%), rgba(255, 255, 255, 0.10)'
            }
        >
            <Div width='fit-content'>
                <Avatar />
            </Div>
            <H3
                width='fit-content'
                pl='3'
                fontSize={{ md: '14px', xl: '21px' }}
                fontWeight='400'
            >
                {content}
            </H3>
        </BackDrop>
    );
};

export default Usage;
