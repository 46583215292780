import React from 'react';
import { Div, Flex, H1, Img, Para, Span } from '../../styles/styledElements';
import hero from '../../assets/images/hero.webp';
import heroMob from '../../assets/images/heroMob.webp';
import { useMediaType } from '../../components/Responsiver';
import Nav from '../../components/Nav';
import Features from './Features';

function Hero({ openContact }) {
    const { isMobile } = useMediaType();

    return (
        <>
            {isMobile ? (
                <>
                    <Div px='5'>
                        <Nav openContact={openContact} />
                    </Div>

                    <Flex
                        mt='8'
                        flexWrap='nowrap'
                        style={{
                            position: 'relative',

                            justifyContent: 'center',
                        }}
                    >
                        <Img
                            src={heroMob}
                            width='100%'
                            alt='Garbage Detective places your needs within the gentle reach of your fingertips.'
                            title='Garbage Detective places your needs within the gentle reach of your fingertips.'
                        />
                        <Flex
                            top={{ xs: '10%', sm: '15%' }}
                            position='absolute'
                            left='16%'
                            center
                            pl='1'
                        >
                            <H1
                                pr='3'
                                width='50%'
                                fontSize={{ xs: '25px', sm: '2rem' }}
                                fontWeight='700'
                                style={{
                                    letterSpacing: '-1px',
                                }}
                            >
                                Meet your companion for{' '}
                                <Span color='#1F2920'>Waste Management</Span>
                            </H1>
                        </Flex>
                    </Flex>
                    <Div
                        px='5'
                        pt='5'
                        pb='3'
                        textAlign='justify'
                        lineHeight='26px'
                        fontSize={{ xs: '17px', sm: 'display6' }}
                        fontWeight='400'
                    >
                        Garbage Detective is a cutting-edge technology that
                        combines cameras, artificial intelligence, and machine
                        learning to detect and monitor illegal garbage dumping.
                        Keep your community clean and green with Garbage
                        Detective.
                    </Div>
                    <Features />
                </>
            ) : (
                <Flex style={{ position: 'relative' }}>
                    <Img
                        src={hero}
                        width='100%'
                        alt='Garbage Detective places your needs within the gentle reach of your fingertips.'
                        title='Garbage Detective places your needs within the gentle reach of your fingertips.'
                    />

                    <Div
                        width='100%'
                        px={{
                            xl: '11',
                            lg: '10',
                            md: '110px',
                            xs: '2',
                            sm: '5',
                        }}
                        style={{
                            position: 'absolute',
                            top: '0%',
                        }}
                    >
                        <Nav openContact={openContact} />
                    </Div>
                    <Flex
                        top={{ md: '23%', lg: '23%', xxl: '25%' }}
                        left={{ md: '19%', xxl: '20%' }}
                        style={{
                            position: 'absolute',
                        }}
                    >
                        <Div width={{md:'50%',xl:'49%', xxl:'51.5%'}}>
                            <H1
                                fontSize={{
                                    md: 'display3',
                                    lg: '40px',
                                    xl: '50px',
                                }}
                                fontWeight='700'
                                lineHeight={{ md: '1.3', xl: '75px' }}
                                style={{
                                    textWrap: 'nowrap',
                                    letterSpacing: '-1px',
                                }}
                            >
                                Meet your companion for
                                <br />
                                <Span color='#1F2920'>Waste Management</Span>
                            </H1>

                            <Para
                                mt={{ lg: '5', md: '3' }}
                                lineHeight={{ md: '1.3', lg: '24px' }}
                                pr={{ xs: '0', md: '4', xxl: '84px' }}
                                textAlign='justify'
                                fontWeight='400'
                                fontSize={{ md: '12px', lg: '17px' }}
                            >
                                Garbage Detective is a cutting-edge technology
                                that combines cameras, artificial intelligence,
                                and machine learning to detect and monitor
                                illegal garbage dumping. Keep your community
                                clean and green with Garbage Detective.
                            </Para>
                        </Div>
                    </Flex>
                    <Div
                        px={{ xl: '9', xxl: '11', md: '6' }}
                        bottom={{ xxl: '6%', xl: '2%', lg: '4%', md: '3%' }}
                        style={{ position: 'absolute' }}
                    >
                        <Features />
                    </Div>
                </Flex>
            )}
        </>
    );
}

export default Hero;
