import Landing from './pages/landing/Index';
import Modal from './components/Modal';
import { Div, Flex } from './styles/styledElements';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContactForm from './components/ContactForm';
import { Toaster } from 'react-hot-toast';
import Layout from './Layout';
import Privacy from './pages/privacy/PrivacyPolicy';
import Terms from './pages/terms/TermsAndC';

function App() {
    const [isContactOpen, setIsContactOpen] = useState(false);
    const openContact = () => {
        setIsContactOpen(true);
    };
    const closeContact = () => {
        setIsContactOpen(false);
    };

    return (
        <Flex justifyContent='center'>
            <Div
                maxWidth='1800px'
                overflow='hidden'
                // fontFamily='normal'
            >
                <Routes>
                    <Route element={<Layout />} path='/' />
                    <Route
                        element={<Landing openContact={openContact} />}
                        index
                    />
                    <Route
                        path='/privacy-policy'
                        element={<Privacy openContact={openContact} />}
                    />
                    <Route
                        path='/terms'
                        element={<Terms openContact={openContact} />}
                    />
                </Routes>

                <Modal
                    show={isContactOpen}
                    onClose={closeContact}
                    size={{ md: '50%', xs: '90%', lg: '35%' }}
                    heading={'Get Garbage Detective 🤩'}
                >
                    <ContactForm closeContact={closeContact} />
                </Modal>
                <Toaster />
            </Div>
        </Flex>
    );
}

export default App;
