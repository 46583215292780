import React from 'react';
import { Div, Flex, Para } from '../../styles/styledElements';
import { useState } from 'react';
import { ReactComponent as Up } from '../../assets/icons/Chevron_Up.svg';
import { ReactComponent as Down } from '../../assets/icons/Chevron_Down.svg';

function DropDown({ topic, content }) {
    const [show, setShow] = useState(false);
    return (
        <Div
            py='4'
            borderBottom='1px solid rgba(0, 0, 0, 0.26)'
            onClick={() => setShow(!show)}
        >
            <Flex
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                color='#000000'
            >
                <Div width='90%' fontWeight='700' fontSize='15px'>
                    {topic}
                </Div>

                <Div width='10%' style={{ cursor: 'pointer' }}>
                    {show ? <Up /> : <Down />}
                </Div>
            </Flex>
            {show && (
                <Para
                    pt='3'
                    color='rgba(107, 114, 128, 1)'
                    lineHeight='1.5'
                    style={{ textAlign: 'justify' }}
                    pb='3'
                    pr='3'
                    fontWeight='400'
                    fontSize='14px'
                >
                    {content}
                </Para>
            )}
        </Div>
    );
}

export default DropDown;

// import React, { useState } from 'react';
// import { ReactComponent as Up } from '../../assets/icons/Chevron_Up.svg';
// import { ReactComponent as Down } from '../../assets/icons/Chevron_Down.svg';
// import { Div, Flex } from '../../styles/styledElements';

// function DropDown() {
//     const [toggle1, setToggle1] = useState(false);
//     const [toggle2, setToggle2] = useState(false);
//     const [toggle3, setToggle3] = useState(false);

//     const health = () => {
//         setToggle1(!toggle1);
//     };
//     const cost = () => {
//         setToggle2(!toggle2);
//     };
//     const enviorment = () => {
//         setToggle3(!toggle3);
//     };

//     return (
//         <Div mt='7' mb='5' color='#111927' fontSize='20px'>
//             <Flex
//                 pb='5'
//                 borderBottom={toggle1 ? '' : '1px solid rgba(0, 0, 0, 0.26)'}
//                 style={{
//                     alignItems: 'center',
//                 }}
//             >
//                 <Div fontWeight='400'>Improved Public Health</Div>

//                 <Div ml='auto' onClick={health} style={{ cursor: 'pointer' }}>
//                     {toggle1 ? <Up /> : <Down />}
//                 </Div>
//             </Flex>
//             {toggle1 ? (
//                 <Div width='100%' color='#6B7280'>
//                     Illegal dumping negatively affects public health by
//                     attracting pests and vermin, and is often linked to
//                     hazardous waste.
//                 </Div>
//             ) : (
//                 ''
//             )}

//             <Flex
//                 py='5'
//                 borderBottom={toggle2 ? '' : '1px solid rgba(0, 0, 0, 0.26)'}
//                 style={{
//                     alignItems: 'center',
//                 }}
//             >
//                 <Div fontWeight='400'>Cost-effective Solution</Div>
//                 <Div
//                     ml='auto'
//                     pt='2'
//                     onClick={cost}
//                     style={{ cursor: 'pointer' }}
//                 >
//                     {toggle2 ? <Up /> : <Down />}
//                 </Div>
//             </Flex>
//             {toggle2 ? (
//                 <Div width='100%' color='#6B7280'>
//                     Garbage Detective reduces the operational and maintenance
//                     costs associated with traditional surveillance methods. It
//                     also provides bonefide recorded timestamped feeds for legal
//                     actions
//                 </Div>
//             ) : (
//                 ''
//             )}

//             <Flex
//                 py='5'
//                 borderBottom={toggle3 ? '' : '1px solid rgba(0, 0, 0, 0.26)'}
//                 style={{
//                     alignItems: 'center',
//                 }}
//             >
//                 <Div mt='3' fontWeight='400'>
//                     A clearer Environment
//                 </Div>
//                 <Div
//                     pt='3'
//                     ml='auto'
//                     onClick={enviorment}
//                     style={{ cursor: 'pointer' }}
//                 >
//                     {toggle3 ? <Up /> : <Down />}
//                 </Div>
//             </Flex>
//             {toggle3 ? (
//                 <Div width='100%' color='#6B7280'>
//                     Illegal dumping damages the environment, posing a threat to
//                     the local fauna and flora, and polluting the air, water, and
//                     soil. Garbage Detective helps to mitigate this impact.
//                 </Div>
//             ) : (
//                 ''
//             )}
//         </Div>
//     );
// }

// export default DropDown;
