import React from 'react';
import { Div } from '../../styles/styledElements';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

function PrivacyPolicy({ openContact }) {
    return (
        <Div>
            <Nav
                openContact={openContact}
                px={{ xs: '6', md: '10' }}
                background='rgba(195, 214, 244, 0.41)'
            />
            <Div px={{ xs: '6', md: '10' }}>
                <h1 style={{ fontSize: '2em' }}>Privacy Policy</h1>

                <p>
                    <strong>Last updated:</strong> 22 – August - 2023
                </p>

                <p>
                    Thank you for choosing Garbage Detective! This Privacy
                    Policy describes how we collect, use, disclose, and
                    safeguard your personal data when you use our website and
                    services. By accessing or using our services, you consent to
                    the practices described in this policy.
                </p>

                <h2>1. Data Controller</h2>

                <h3>1.1 Data Controller:</h3>
                <p>
                    Roade Labs Pvt Ltd, located at 27/2088, 2nd Floor, Adam Star
                    Building, Mavelipuram Zone 2, Kakkanad, Ernakulam, Kerala,
                    India - 682030, is the data controller responsible for the
                    collection, processing, and protection of your personal
                    data.
                </p>

                <h2>2. Personal Data We Collect</h2>

                <h3>2.1 Information You Provide:</h3>
                <p>
                    When you use our services, you may provide us with personal
                    data, including but not limited to your name, email address,
                    and contact information.
                </p>

                <h3>2.2 Usage Data:</h3>
                <p>
                    We may collect information about your usage of our website,
                    including pages visited, actions taken, and preferences.
                </p>

                <h2>3. How We Use Your Personal Data</h2>

                <h3>3.1 Service Provision:</h3>
                <p>
                    We use your personal data to provide, maintain, and improve
                    our services, as well as to respond to your inquiries and
                    requests.
                </p>

                <h3>3.2 Communication:</h3>
                <p>
                    We may send you emails to provide updates about our
                    services, respond to your inquiries, or notify you of
                    changes.
                </p>

                <h2>4. Legal Basis for Processing</h2>

                <h3>4.1 Consent:</h3>
                <p>
                    By using our services, you consent to the collection and
                    processing of your personal data as described in this
                    Privacy Policy.
                </p>

                <h3>4.2 Legitimate Interests:</h3>
                <p>
                    We may process your personal data for our legitimate
                    interests, such as improving our services and maintaining
                    security.
                </p>

                <h2>5. Data Sharing and Disclosure</h2>

                <h3>5.1 Third Parties:</h3>
                <p>
                    We may share your personal data with trusted third-party
                    service providers to enable the operation of our services.
                    We ensure that these third parties are GDPR-compliant and
                    have appropriate security measures.
                </p>

                <h2>6. Your Rights</h2>

                <h3>6.1 Access and Rectification:</h3>
                <p>
                    You have the right to access and correct your personal data
                    held by us.
                </p>

                <h3>6.2 Erasure:</h3>
                <p>
                    You can request the erasure of your personal data from our
                    systems.
                </p>

                <h3>6.3 Data Portability:</h3>
                <p>
                    You have the right to receive a copy of your personal data
                    in a structured, machine-readable format.
                </p>

                <h3>6.4 Withdrawal of Consent:</h3>
                <p>
                    If you have provided your consent for data processing, you
                    can withdraw it at any time.
                </p>

                <h2>7. Data Security</h2>

                <h3>7.1 Data Security:</h3>
                <p>
                    We implement appropriate technical and organizational
                    measures to protect your personal data against unauthorized
                    access, disclosure, alteration, or destruction.
                </p>

                <h2>8. Contact Us</h2>

                <h3>8.1 Contact Information:</h3>
                <p>
                    If you have any questions about our Privacy Policy or data
                    processing practices, please contact us at{' '}
                    <a href='mailto:support@garbagedetective.com'>
                        support@garbagedetective.com
                    </a>
                    .
                </p>

                <h2>9. Changes to Privacy Policy</h2>

                <h3>9.1 Updates:</h3>
                <p>
                    We reserve the right to update this Privacy Policy. Changes
                    will be posted on our website and will be effective upon
                    posting.
                </p>

                <p>
                    By using our services, you acknowledge that you have read,
                    understood, and agreed to this Privacy Policy.
                </p>
            </Div>
            <Div
                background='rgba(195, 214, 244, 0.41)'
                px={{ xs: '6', md: '10' }}
                pt={{ md: '7', xs: '4' }}
                pb={{ md: '7', xs: '6' }}
                mt={{ md: '8', xs: '6' }}
            >
                <Footer />
            </Div>
        </Div>
    );
}

export default PrivacyPolicy;
