const radii = [
    '0px',
    '2px',
    '4px',
    '8px',
    '10px',
    '16px',
    '24px',
    '36px',
    '48px',
    '90px',
];

const space = [0, 4, 8, 10, 16, 24, 32, 48, 64, 128, 156, 200];

const theme = {
    breakpoints: {},

    space,
    fontSizes: {},
    // lineHeights,
    fonts: {
        normal: 'Inter',
    },

    colors: {
        blue: '#577CFF',
        green: '#28A263',
        matteOrange: '#FF6C4B',
        grays: {
            900: '#404040',
            800: '#22242F',
            700: '#808080',
            600: '#868898',
            500: '#9C9EAB',
        },
        pink: '#EAB8BB',
        yellow: '#EFB522',
    },

    radii,
};

const fontSizes = [
    '.75rem',
    '1rem',
    '1.1rem',
    '1.2rem',
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2.3rem',
    '3rem',
];

theme.fontSizes.heading = fontSizes[8];
theme.fontSizes.display1 = fontSizes[7];
theme.fontSizes.display2 = fontSizes[6];
theme.fontSizes.display3 = fontSizes[5];
theme.fontSizes.display4 = fontSizes[4];
theme.fontSizes.display5 = fontSizes[3];
theme.fontSizes.display6 = fontSizes[2];
theme.fontSizes.normal = fontSizes[1];
theme.fontSizes.small = fontSizes[0];

theme.breakpoints.xs = '320px';
theme.breakpoints.sm = '480px';
theme.breakpoints.md = '767px';
theme.breakpoints.lg = '1024px';
theme.breakpoints.xl = '1200px';
theme.breakpoints.xxl = '1366px';

export default theme;
