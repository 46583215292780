import React from 'react';
import { Div, Flex, H2, H3, Img, Span } from '../../styles/styledElements';
import Group from '../../assets/icons/Group';
import { ReactComponent as Build } from '../../assets/icons/building.svg';
import { ReactComponent as Decision } from '../../assets/icons/join.svg';
import landingMob from '../../assets/images/landingPageMob.webp';
import uniqMob from '../../assets/images/uniqueMob.webp';
import Carousel from 'react-elastic-carousel';
import { useMediaType } from '../../components/Responsiver';
import styled from 'styled-components';
const BackDrop = styled(Div)`
    backdrop-filter: 'blur(40px)';
`;
function HowWork() {
    const { isMobile, isDesktop } = useMediaType();

    const cards = [
        {
            id: 0,
            value: (
                <FeatureCard
                    topic={
                        <Span>
                            Cameras capture
                            <br /> feeds 24/7
                        </Span>
                    }
                    content='Garbage Detective uses high-quality cameras to capture images of the surrounding environment, 24/7.'
                    icon={<Group />}
                />
            ),
        },
        {
            id: 1,
            value: (
                <FeatureCard
                    topic={
                        <Span>
                            Images are
                            <br /> processed
                        </Span>
                    }
                    content='The collected images are then processed using advanced computer vision and machine learning algorithms to identify garbage dumpers.'
                    icon={<Build />}
                />
            ),
        },
        {
            id: 2,
            value: (
                <FeatureCard
                    topic={
                        <Span>
                            Alerts are <br />
                            sent
                        </Span>
                    }
                    content='When an illegal dumping activity is detected, Garbage Detective sends alerts to the relevant authorities for quick action.'
                    icon={<Decision />}
                />
            ),
        },
    ];

    return (
        <Div
            pl={{ xs: '4', md: '0', lg: '7', xl: '77px' }}
            pr={{ xs: '0', md: '0', lg: '7' }}
            mt={{ lg: '5', md: '2', xs: '0', xl: '7' }}
            pt={{ md: '5', xs: '5' }}
        >
            <H2
                fontSize={{ md: '30px', xs: '22px' }}
                fontWeight='700'
                // pl={{ md: '5', xs: '0' }}
            >
                How it Works
            </H2>

            {!isDesktop ? (
                <>
                    <Div mt='5' px='5'>
                        <Carousel
                            showArrows={false}
                            itemsToShow={isMobile ? 1 : 2}
                        >
                            {cards.map((slideContent, index) => (
                                <Div pr='3' width='95%'>
                                    {slideContent.value}
                                </Div>
                            ))}
                        </Carousel>
                    </Div>
                    <Flex
                        flexWrap='nowrap'
                        mt='6'
                        pr='0'
                        style={{ justifyContent: 'space-around' }}
                    >
                        <Div width='46%' pr='1'>
                            <Img
                                src={landingMob}
                                width='100%'
                                loading='lazy'
                                alt='Screen featuring the home Page of Garbage Detective mobile application.'
                                title='Screen featuring the home Page of Garbage Detective mobile application.'
                            />
                        </Div>
                        <Div width='51%' pl='1'>
                            <Img
                                src={uniqMob}
                                width='100%'
                                loading='lazy'
                                alt='A Triple Exposure Image of a Gentleman '
                                title='A Triple Exposure Image of a Gentleman '
                            />
                        </Div>
                    </Flex>
                </>
            ) : (
                <Div borderBottom='1px solid rgba(0, 0, 0, 0.26)'>
                    <Flex
                        width='100%'
                        pb='8'
                        justifyContent='space-between'
                        mt='7'
                    >
                        {cards.map((data) => (
                            <Div width='30%'>{data.value}</Div>
                        ))}
                    </Flex>
                </Div>
            )}
        </Div>
    );
}

const FeatureCard = ({ topic, icon, content }) => {
    return (
        <Div height='100%'>
            <BackDrop
                p={{ lg: '6', md: '4', xs: '5' }}
                height='100%'
                background='rgba(255, 255, 255, 0.90)'
                flexDirection='column'
                center
                borderRadius={{ md: '30px', xs: '35px' }}
                style={{
                    boxShadow: '0px 4px 10px 1px rgba(0, 0, 0, 0.08)',
                }}
            >
                <Flex center>
                    <Flex
                        height='50px'
                        width='49px'
                        background='var(--tint-t-5, #E8F5E9)'
                        center
                        style={{ borderRadius: '18px 5px 10px 5px' }}
                    >
                        {icon}
                    </Flex>
                </Flex>

                <H3
                    color='#4D4D4D'
                    fontSize={{
                        md: '25px',
                        xs: '20px',
                    }}
                    fontWeight='700'
                    mt='3'
                    textAlign='center'
                >
                    {topic}
                </H3>
                <Flex
                    fontSize='14px'
                    color={{ xs: '#6B7280', md: '#717171' }}
                    lineHeight='20px'
                    mt='4'
                    center
                    textAlign='center'
                    fontWeight='400'
                >
                    {content}
                </Flex>
            </BackDrop>
        </Div>
    );
};

export default HowWork;
