import React from 'react';
import {
    Button,
    Div,
    Flex,
    H2,
    Img,
    Picture,
} from '../../styles/styledElements';
import nature from '../../assets/images/end.webp';
import natureMob from '../../assets/images/endMob.webp';

function End({ openContact }) {
    return (
        <Div
            px={{ xl: '11', lg: '9', md: '7', xs: '2' }}
            mt={{ md: '6', xs: '0' }}
        >
            <Flex
                style={{
                    position: 'relative',
                    justifyContent: 'center',
                }}
                alignItems = {{md:'center'}}
            >
                <Picture>
                    <source media='(min-width:767px)' srcSet={nature} />
                    <Img
                        src={natureMob ?? nature}
                        alt='Screen featuring a beautiful farm field'
                        title='Screen featuring a beautiful farm field'
                        width='100%'
                        borderRadius='15px'
                        loading='lazy'
                    />
                </Picture>

                <Div
                    color='#ffffff'
                    style={{ position: 'absolute' }}
                    textAlign='center'
                    pt={{ xl: '8', xs: '38%',md:'0'}}
                >
                    <H2 fontSize={{ lg: '48px', xs: '29px' }} fontWeight='700'lineHeight = '52px'>
                        The Planet Needs
                        <br />
                        You
                    </H2>
                    <Div mt={{xs:'4',md:'7'}}>
                        <Button
                            background='#415B43'
                            color='#ffffff'
                            px={{ xl: '7', xs: '22px' }}
                            py={{ xl: '6', xs: '22px' }}
                            borderRadius='980px'
                            fontSize='15px'
                            border='none'
                            fontWeight={{ md: '700', xs: '400' }}
                            onClick={openContact}
                        >
                            Get a Free Demo
                        </Button>
                    </Div>
                </Div>
            </Flex>
        </Div>
    );
}

export default End;
