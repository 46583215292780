import React from 'react';
import { Div, Flex, Img, Span } from '../../styles/styledElements';
import user1 from '../../assets/images/testimonial1.png';
import user2 from '../../assets/images/tesimonial2.png';
import user3 from '../../assets/images/tesimonial3.png';
import user4 from '../../assets/images/tesimonial4.png';
import { ScrollArea } from '../../styles/styledComponenets';

function Testimonials() {
    const cards = [
        {
            id: 0,
            value: (
                <TestimonialCard
                    img={user1}
                    name='Claire Bell'
                    designation='Designer'
                />
            ),
        },
        {
            id: 1,
            value: (
                <TestimonialCard
                    img={user2}
                    name='Francisco Lane'
                    designation='Designer'
                />
            ),
        },
        {
            id: 2,
            value: (
                <TestimonialCard
                    img={user3}
                    name='Ralph Fisher'
                    designation='Designer'
                />
            ),
        },
        {
            id: 3,
            value: (
                <TestimonialCard
                    img={user4}
                    name='Jorge Murphy'
                    designation='Designer'
                />
            ),
        },
    ];

    return (
        <Div pl={{ md: '8', xs: '5' }} pr={{ md: '8', xs: '0' }}>
            <Span fontSize='display2' fontWeight='400'>
                Testimonials
            </Span>
            <Flex
                center
                mt='7'
                justifyContent='space-around'
                px='5'
                display={{ md: 'flex', xs: 'none' }}
            >
                {cards.map((data) => (
                    <Div width='48%'>{data.value}</Div>
                ))}
            </Flex>
            <Div display={{ xs: 'block', md: 'none' }} mt='5' pb='5'>
                <ScrollArea
                    width='100%'
                    mt='5'
                    pb='4'
                    style={{ overflowX: 'auto' }}
                    display={{ md: 'none', xs: 'block' }}
                >
                    <Flex width='320%' flexWrap='noWrap'>
                        {cards.map((data) => (
                            <Div width='80%' pr='3'>
                                {data.value}
                            </Div>
                        ))}
                    </Flex>
                </ScrollArea>

                <Div mt='5'>
                    <Flex
                        style={{
                            justifyContent: 'center',
                        }}
                    >
                        {cards.map((data, index) => (
                            <Div
                                width='10%'
                                // onClick={() => {
                                //     updateIndex(index);
                                // }}
                                key={data.id}
                            >
                                <Div
                                    height='9px'
                                    width='9px'
                                    style={{ borderRadius: '50%' }}
                                    background='rgba(0, 0, 0, 0.13)'
                                />
                            </Div>
                        ))}
                    </Flex>
                </Div>
            </Div>
        </Div>
    );
}

const TestimonialCard = ({ img, name, designation }) => {
    return (
        <Div
            p='6'
            background='#FAFBFE'
            border='1px solid rgba(0, 0, 0, 0.26)'
            borderRadius={{ md: '10px', xs: '35px' }}
        >
            <Flex justifyContent='space-between' center>
                <Div width={{ md: '10%', xs: '20%' }}>
                    <Img src={img} width='100%' alt='user' title='user' />
                </Div>
                <Div
                    width={{ md: '88%', xs: '77%' }}
                    fontWeight='500'
                    fontSize='normal'
                >
                    {name}
                    <Div>{designation}</Div>
                </Div>
            </Flex>
            <Div mt='5' pr={{ md: '8', xs: '0' }}>
                Slate helps you see how many more days you need to work to reach
                your financial goal for the month and year. Slate helps you see
                how many more days you need to work to reach your financial goal
                for the month and year.your financial goal for the month and
                year.
            </Div>
        </Div>
    );
};
export default Testimonials;
