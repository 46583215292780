import React from 'react';
import { useMediaType } from '../../components/Responsiver';

export default function Eye() {
    const { isMobile, isTablet } = useMediaType();

    return (
        <svg
            width={isTablet ? '25' : '32'}
            height={isTablet ? '25' : '32'}
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g id='eye'>
                <path
                    id='Vector'
                    d='M15.9998 19.1998C16.8485 19.1998 17.6624 18.8627 18.2625 18.2625C18.8627 17.6624 19.1998 16.8485 19.1998 15.9998C19.1998 15.1511 18.8627 14.3372 18.2625 13.7371C17.6624 13.1369 16.8485 12.7998 15.9998 12.7998C15.1511 12.7998 14.3372 13.1369 13.7371 13.7371C13.1369 14.3372 12.7998 15.1511 12.7998 15.9998C12.7998 16.8485 13.1369 17.6624 13.7371 18.2625C14.3372 18.8627 15.1511 19.1998 15.9998 19.1998Z'
                    fill='#6B7280'
                />
                <path
                    id='Vector_2'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0.732422 15.9998C2.77082 9.50861 8.83482 4.7998 15.9996 4.7998C23.1644 4.7998 29.2284 9.50861 31.2668 15.9998C29.2284 22.491 23.1644 27.1998 15.9996 27.1998C8.83482 27.1998 2.77082 22.491 0.732422 15.9998ZM22.3996 15.9998C22.3996 17.6972 21.7253 19.3251 20.5251 20.5253C19.3249 21.7255 17.697 22.3998 15.9996 22.3998C14.3022 22.3998 12.6744 21.7255 11.4741 20.5253C10.2739 19.3251 9.59962 17.6972 9.59962 15.9998C9.59962 14.3024 10.2739 12.6746 11.4741 11.4743C12.6744 10.2741 14.3022 9.59981 15.9996 9.5998C17.697 9.59981 19.3249 10.2741 20.5251 11.4743C21.7253 12.6746 22.3996 14.3024 22.3996 15.9998Z'
                    fill='#6B7280'
                />
            </g>
        </svg>
    );
}
