import React from 'react';
import { useMediaType } from '../../components/Responsiver';

function PieChart() {
    const { isMobile } = useMediaType();

    return (
        <svg
            width={isMobile ? '28' : '32'}
            height={isMobile ? '28' : '32'}
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.2002 16.0002C3.2002 12.6054 4.54876 9.34969 6.94923 6.94923C9.34969 4.54876 12.6054 3.2002 16.0002 3.2002V16.0002H28.8002C28.8002 19.395 27.4516 22.6507 25.0512 25.0512C22.6507 27.4516 19.395 28.8002 16.0002 28.8002C12.6054 28.8002 9.34969 27.4516 6.94923 25.0512C4.54876 22.6507 3.2002 19.395 3.2002 16.0002Z'
                fill='#6B7280'
            />
            <path
                d='M19.2002 3.60352C21.414 4.17706 23.4341 5.33223 25.0512 6.94931C26.6683 8.56638 27.8235 10.5865 28.397 12.8003H19.2002V3.60352Z'
                fill='#6B7280'
            />
        </svg>
    );
}

export default PieChart;
